<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>Compoff Approval Details</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                    <router-link :to="{name:'compoff-approvals'}">Compoff Approvals</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">Compoff Details</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div v-if="api_success">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="success"
                    @dismissed="alertDismissCountDown = 0"
                    @dismiss-count-down="alertCountDownChanged"
                >   {{ api_success }}
                </b-alert>
            </div>
            <div class="col-md-12" v-if="compoff_approval_details">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title">
                            <h5>Compoff Details</h5>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <label class="col-md-3">
                                <b>Applied Date:</b>
                            </label>
                             <div class="col-md-9">
                                {{compoff_approval_details.date}}
                            </div>
                        </div>

                         <div class="form-group row" v-if="compoff_approval_details.hasOwnProperty('employee')">
                            <label class="col-md-3">
                                <b>Applied By:</b>
                            </label>
                            <div class="col-md-9">
                                {{compoff_approval_details.employee.full_name}} [IES-{{compoff_approval_details.emp_id}}]
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                <b>CompOff Type:</b>
                            </label>
                             <div class="col-md-9">
                                {{compoff_approval_details.type}}
                            </div>
                        </div>

                         <div class="form-group row">
                            <label class="col-md-3">
                                <b>Reason:</b>
                            </label>
                             <div class="col-md-9">
                                {{compoff_approval_details.reason}}
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-md-3">
                                <b>Status:</b>
                            </label>
                            <div class="col-md-9">
                                <span><b-badge :variant="setBadgeColor(compoff_approval_details.status)">{{compoff_approval_details.status}}</b-badge></span>
                            </div>
                        </div>

                        <div class="form-group row" >
                            <label class="col-md-3">
                                <b>Reporting To:</b>
                            </label>
                            <div class="col-md-9">
                                {{compoff_approval_details.employee.reporting_to.full_name}} [IES-{{compoff_approval_details.employee.reporting_to.emp_id}}]
                            </div>
                        </div>

                        <div class="form-group row" v-if="compoff_approval_details.status == 'APPROVED'">
                            <label class="col-md-3">
                                <b>Approved By:</b>
                            </label>
                            <div class="col-md-9">
                                {{compoff_approval_details.approved_by_employee.full_name}} [IES-{{compoff_approval_details.emp_id}}]
                            </div>
                        </div>

                        <div class="form-group row" v-if="compoff_approval_details.status == 'APPROVED'">
                            <label class="col-md-3">
                                <b>Approved at:</b>
                            </label>
                            <div class="col-md-9">
                                 {{compoff_approval_details.approved_at}}
                            </div>
                        </div>

                        <div class="form-group row" v-if="compoff_approval_details.status == 'REJECTED'">
                            <label class="col-md-3">
                                <b>Rejected By:</b>
                            </label>
                            <div class="col-md-9">
                                 {{compoff_approval_details.approved_by_employee.full_name}} [IES-{{compoff_approval_details.emp_id}}]
                            </div>
                        </div>

                        <div class="form-group row" v-if="compoff_approval_details.status == 'REJECTED'">
                            <label class="col-md-3">
                                <b>Rejected at:</b>
                            </label>
                            <div class="col-md-9">
                                {{compoff_approval_details.approved_at}}
                            </div>
                        </div>


                        <div class="form-group row" style="float:right;">
                            <div class="offset-xs-3 col-xs-9" v-if="compoff_approval_details.status == 'APPLIED'">
                                <button
                                    type="button"
                                    class="btn btn-success text-right ma-10px"
                                    @click="compoffApprovalInfo(compoff_approval_details.id,'approve')"
                                >Approve</button>
                            </div>
                            <div class="offset-xs-3 col-xs-9" v-if="compoff_approval_details.status == 'APPLIED'">
                                <button
                                    type="button"
                                    class="btn btn-danger text-right ma-10px"
                                    @click="compoffApprovalInfo(compoff_approval_details.id,'reject')"
                                >Reject</button>
                            </div>
                            <div class="offset-xs-3 col-xs-9">
                                <button
                                    type="button"
                                    class="btn btn-secondary ma-10px"
                                    @click="$router.go(-1)"
                                >Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>

        <!-- Cancel modal -->
        <b-modal
            :id="compoffApprovalModal.id"
            :title="compoffApprovalModal.title"
            centered
            size="md"
            @hide="resetcompoffApprovalModal"
        >
            <p v-html="message" class="float-left"></p>
            <br> <br>
            <label class="float-left" v-if="compoffApprovalModal.value === 'reject'">
                Comment
                <span class="text-danger" style="font-weight:bold;">*</span>
            </label>
            <b-form-textarea
                v-if="compoffApprovalModal.value === 'reject'"
                placeholder="Reject Reason..."
                v-model="comment"
                rows="5"
            ></b-form-textarea>
            <div slot="modal-footer" class="w-100">
                <b-button class="mt-3 float-right" @click="cancel()">No</b-button>
                <b-button
                    v-if="compoffApprovalModal.value === 'reject'"
                    class="mt-3 float-right btn btn-primary"
                    variant="danger"
                    :disabled="!comment"
                    v-on:click="compOffApproval(compoffApprovalModal.compoff_approval_id, compoffApprovalModal.status)"
                    style="margin-right:10px;"
                >Yes</b-button>
                <b-button
                    v-else
                    class="mt-3 float-right"
                    variant="danger"
                    v-on:click="compOffApproval(compoffApprovalModal.compoff_approval_id, compoffApprovalModal.status)"
                    style="margin-right:10px;"
                >Yes</b-button>
            </div>
        </b-modal>

    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            api_error: "",
            api_success: "",
            access: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            compoffApprovalModal: {
                id: 'leave-approval',
                title: "",
                compoff_approval_id:null,
                status:"",
                value:""
            },
            comment:"",
            compoff_approval_details:null,
            message:"",
            badge_colors:[
                {
                    status: "APPLIED",
                    color:'info'
                },
                {
                    status: "APPROVED",
                    color:'success'
                },
                {
                    status: "REJECTED",
                    color:'danger'
                }
            ],
        };
    },
    mounted() {
        this.access = this.hasAnyPermission(["CAN_APPROVE_ALL_COMP_OFFS", "CAN_APPROVE_COMP_OFFS"]);
        this.getCompoffApproval();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        compoffApprovalInfo(id, value) {
            if(value == 'reject') {
                this.message = 'Are you sure you want to Reject this Compoff?';
                this.compoffApprovalModal.title = `Reject Confirmation`;
                this.compoffApprovalModal.value = value;
            } else {
                this.message = 'Are you sure you want to Approve this Compoff?';
                this.compoffApprovalModal.title = `Approve Confirmation`;
                this.compoffApprovalModal.value = value;
            }
            this.compoffApprovalModal.status = value;
            this.compoffApprovalModal.compoff_approval_id = id;
            this.$root.$emit("bv::show::modal", this.compoffApprovalModal.id);
        },

        resetcompoffApprovalModal() {
            this.compoffApprovalModal.status = "";
            this.message = "";
            this.compoffApprovalModal.title = "";
            this.compoffApprovalModal.compoff_approval_id = null;
        },

                
        compOffApproval(id, status) {
            if (status == 'reject') {
                this.rejectCompOff(id);
            } else {
                this.approveCompOff(id)
            }
        },

        approveCompOff(id) {
            this.startProgressBar();
              let query = this.getAuthHeaders();
            query['params'] = {};
            this.axios.post(this.$api.approve_compoff, { id: id}, query).then(() => {
                this.$root.$emit("bv::hide::modal", this.compoffApprovalModal.id);
                this.api_error = "";
                this.$router.push({
                    name: 'compoff-approvals'
                });
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.cancel();
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        rejectCompOff(id) {
            this.startProgressBar();
            this.axios.post(this.$api.reject_compoff, {id:id,comment:this.comment}, this.getAuthHeaders()).then(() => {
                this.$root.$emit("bv::hide::modal", this.compoffApprovalModal.id);
                this.api_error = "";
                this.$router.push({
                    name: 'compoff-approvals'
                });
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.cancel();
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        getCompoffApproval() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query['params'] = {
                id: this.$route.params.id
            }
            this.axios.get(this.$api.get_compoffs, query).then(response => {
                this.compoff_approval_details = response.data.data;
                this.updateProgressBar(true);
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        cancel() {
            this.$root.$emit("bv::hide::modal", this.compoffApprovalModal.id);
        },

        setBadgeColor(value) {
            let data = this.badge_colors.find(status => status.status == value);
            if (data) {
                return data.color
            }
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
.leave-reason{
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell,
    "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 0.875rem;
}
</style>